@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Staatliches&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.htmlWrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden !important;
  position: fixed;
  top: 0;
  left: 0;
}

body {
  overflow: hidden !important;
  position: relative;
  height: 100vh !important;
  width: 100vw !important;
  background-image: radial-gradient(#fafafa 0%, #f2f2ec 53%, #e6e1c7 100%);
}

.bgColoring {
  background-image: radial-gradient(#f7f7f7 0%, #f2f2ec 53%, #ece9da 100%);
  height: 100vh;
  width: 100vw;
}

.topLeftText {
  font-family: "Satisfy";
  font-size: 30pt;
  position: absolute;
  left: 40px;
  top: 30px;
  color: rgba(50, 50, 50, 0.8);
  text-shadow: -2px 2px 0px #e2e226;
  -webkit-animation-duration: 0.7s;
          animation-duration: 0.7s;
}

.menuContainer {
  transition: 0.2s ease all;
  position: fixed;
  top: 30px;
  right: -2px;
  height: 50px;
  width: 50px;
  border-radius: 5px 0px 0px 5px;
  -webkit-animation-duration: 0.7s;
          animation-duration: 0.7s;
  opacity: 0.7;
}

.menuContainer:hover {
  cursor: pointer;
  top: 25px;
  opacity: 0.95;
}

.menuContainer .menuBtn {
  position: absolute;
  top: 35px;
  right: 25px;
  transition: 0.8s ease all;
  padding: 5px;
  padding-top: 10px;
  height: 50px;
  width: 50px;
}

.menuContainer .menuBtn .menuTop {
  width: 35px;
  height: 5px;
  background-color: #323232;
  border-radius: 1px;
  box-shadow: -2px 2px 0px #e2e226;
  transition: 0.8s ease all;
}

.menuContainer .menuBtn .menuMid {
  width: 35px;
  height: 5px;
  background-color: #323232;
  margin-top: 8px;
  border-radius: 1px;
  box-shadow: -2px 2px 0px #e2e226;
  transition: 0.5s ease all;
}

.menuContainer .menuBtn .menuBot {
  width: 35px;
  height: 5px;
  background-color: #323232;
  margin-top: 8px;
  border-radius: 1px;
  box-shadow: -2px 2px 0px #e2e226;
  transition: 0.8s ease all;
}

.menuContainer .activeMenuBtn {
  transition: 0.8s ease all;
  padding-top: 20px;
}

.menuContainer .activeMenuBtn .menuTop {
  transform: rotate(-45deg);
  margin-bottom: -4.5px;
  transition: 0.8s ease all;
}

.menuContainer .activeMenuBtn .menuMid {
  transition: 0.8s ease all;
  transform: rotate(45deg);
  margin-top: 0;
}

.menuContainer .activeMenuBtn .menuBot {
  margin-left: 120px;
  transition: 0.8s ease all;
}

.menuContainer .navlogo {
  height: 180px;
  opacity: 0.8;
}

.navbar {
  position: absolute;
  top: 120px;
  right: -110px;
  text-align: right;
  transition: 0.8s ease all;
}

.navbar .navItem {
  font-family: "Raleway";
  color: #323232;
  transition: 0.2s ease all;
  opacity: 0.7;
}

.navbar .navItem:hover {
  padding-right: 5px;
  cursor: pointer;
  opacity: 0.95;
}

.activeNav {
  top: 120px;
  right: 47px;
  transition: 0.8s ease all;
}

.activeNavItem {
  border-right: 5px solid #323232;
}

.mainOpenerDiv .bgImage {
  opacity: 0.2;
  z-index: -5;
}

.mainOpenerDiv .middleSectionContainer {
  width: 180px;
  height: 400px;
  position: absolute;
  top: 50vh;
  left: 50vw;
  margin-top: -200px;
  margin-left: -90px;
}

.mainOpenerDiv .middleSectionContainer .floatingHand {
  width: 100%;
  -webkit-animation-duration: 0.7s;
          animation-duration: 0.7s;
  opacity: 0.85;
}

.mainOpenerDiv .middleSectionContainer .shadow {
  width: 100%;
  margin-left: 10px;
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
}

.socials {
  position: absolute;
  bottom: 45px;
  left: 50vw;
  width: 300px;
  margin-left: -150px;
  -webkit-animation-duration: 0.7s;
          animation-duration: 0.7s;
}

.socials .fbIcon {
  width: 33%;
  color: rgba(50, 50, 50, 0.8);
  transition: 0.2s ease all;
}

.socials .fbIcon:hover {
  color: rgba(50, 50, 50, 0.95);
  margin-bottom: 3px;
}

.socials .instaIcon {
  width: 33%;
  color: rgba(50, 50, 50, 0.8);
  transition: 0.2s ease all;
}

.socials .instaIcon:hover {
  color: rgba(50, 50, 50, 0.95);
  margin-bottom: 3px;
}

.socials .ytIcon {
  width: 33%;
  color: rgba(50, 50, 50, 0.8);
  transition: 0.2s ease all;
}

.socials .ytIcon:hover {
  color: rgba(50, 50, 50, 0.95);
  margin-bottom: 3px;
}

.about {
  height: 700px !important;
  margin-top: -350px !important;
}

.tab {
  background-color: rgba(247, 247, 247, 0.97);
  height: 600px;
  width: 900px;
  position: absolute;
  right: -50%;
  top: 50%;
  margin-right: -450px;
  margin-top: -300px;
  box-shadow: 0px 0px 8px rgba(50, 50, 50, 0.55);
  transition: 0.8s ease all;
}

.tab h1 {
  font-family: "Satisfy";
  font-size: 35pt;
  color: rgba(60, 60, 60, 0.9);
  position: absolute;
  top: -10px;
  left: 40px;
  letter-spacing: 5px;
  text-shadow: -2px 2px 0px #e2e226;
}

.tab .tabExit {
  position: absolute;
  top: -10px;
  right: -15px;
  height: 30px;
  width: 30px;
  opacity: 0.7;
  transition: 0.2s ease all;
}

.tab .tabExit:hover {
  cursor: pointer;
  opacity: 0.95;
}

.tab .tabExit .exit1 {
  width: 25px;
  height: 4px;
  background-color: #323232;
  border-radius: 1px;
  margin-bottom: -3.5px;
  box-shadow: -2px 2px 0px #e2e226;
  transform: rotate(45deg);
  position: absolute;
  top: 10px;
}

.tab .tabExit .exit2 {
  width: 25px;
  height: 4px;
  background-color: #323232;
  border-radius: 1px;
  box-shadow: -2px 2px 0px #e2e226;
  transform: rotate(-45deg);
  position: absolute;
  top: 10px;
}

.mediaContainer {
  position: absolute;
  width: 75%;
  height: 70%;
  top: 50%;
  left: 50%;
  margin-top: -20%;
  margin-left: -37.5%;
  overflow: hidden;
}

.mediaContainer .galpic {
  width: 100%;
}

.mediaContainer .carousel__slider {
  height: 50vh;
}

.mediaContainer .backBtn {
  position: absolute;
  left: -5px;
  bottom: 0;
  height: 100%;
  background-color: silver;
  color: #3b3b3b;
  border: none;
  width: 50px;
  opacity: 0.7;
  font-size: 20pt;
  transition: 0.2s ease all;
}

.mediaContainer .backBtn:hover {
  opacity: 0.8;
  left: -8px;
}

.mediaContainer .nextBtn {
  position: absolute;
  font-size: 20pt;
  right: -5px;
  bottom: 0;
  height: 100%;
  background-color: silver;
  color: #3b3b3b;
  border: none;
  width: 50px;
  opacity: 0.7;
  transition: 0.3s ease all;
}

.mediaContainer .nextBtn:hover {
  opacity: 0.8;
  right: -8px;
}

.mediaContainer .dotgroup {
  position: absolute;
  width: 100%;
  bottom: 10px;
  z-index: 10;
  text-align: center;
}

.mediaContainer .dotgroup .dot___3c3SI,
.mediaContainer .dotgroup .carousel__dot {
  overflow: hidden;
  height: 16px;
  border: none;
  background-color: #f1f1f1;
  margin: 0 10px;
}

.mediaContainer .dotgroup .carousel__dot--selected {
  background-color: rgba(253, 241, 68, 0.95);
}

.schedule h3 {
  position: absolute;
  bottom: 140px;
  left: 50px;
}

.schedule h4 {
  position: absolute;
  bottom: 90px;
  left: 145px;
  font-family: "Raleway";
  font-size: 16pt;
  border-bottom: 1px solid rgba(50, 50, 50, 0.55);
  padding-bottom: 3px;
}

.schedule .showsList {
  position: absolute;
  bottom: 30px;
  left: 50%;
  width: 630px;
  margin-left: -315px;
  display: -ms-grid;
  display: grid;
  grid-row-gap: 15px;
  padding: 0;
}

.schedule .showsList li {
  list-style: none;
  font-family: "Raleway";
  font-size: 13pt;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 30% 30% 15% 25%;
      grid-template-columns: 30% 30% 15% 25%;
}

.schedule .showsList li a {
  color: blue;
}

.schedule .showsList .icon {
  margin-bottom: -2px;
  margin-right: 3px;
}

.schedule .schedCal {
  width: 70%;
  position: absolute;
  top: 100px;
  left: 50%;
  margin-left: -35%;
  margin-bottom: 10px;
}

.schedule .schedCal .react-calendar__navigation {
  margin-bottom: 0;
  margin-top: 10px;
}

.schedule .schedCal .react-calendar__navigation .react-calendar__navigation__arrow {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  font-family: "Raleway";
  font-size: 22pt;
  margin: 0 20px;
  font-weight: 900;
  color: #323232;
  transition: 0.2s ease all;
}

.schedule .schedCal .react-calendar__navigation .react-calendar__navigation__arrow:hover {
  cursor: pointer;
  margin-top: -5px;
}

.schedule .schedCal .react-calendar__navigation .react-calendar__navigation__next2-button {
  visibility: collapse;
}

.schedule .schedCal .react-calendar__navigation .react-calendar__navigation__prev2-button {
  visibility: collapse;
}

.schedule .schedCal .react-calendar__navigation .react-calendar__navigation__label {
  border: none;
  color: #323232;
  background-color: rgba(0, 0, 0, 0);
  font-family: "Raleway";
  font-size: 25pt;
}

.schedule .schedCal .react-calendar__month-view__days__day--weekend {
  color: black !important;
}

.schedule .schedCal .react-calendar__tile--now {
  background-color: rgba(255, 250, 185, 0.95) !important;
  color: black;
}

.schedule .schedCal .react-calendar__tile--now:hover {
  background-color: rgba(252, 244, 141, 0.95) !important;
}

.schedule .schedCal .react-calendar__month-view__days__day--neighboringMonth {
  color: rgba(136, 136, 136, 0.5) !important;
}

.schedule .schedCal .react-calendar__tile--active {
  color: black;
  border: 1px solid red;
  background-color: rgba(136, 136, 136, 0.25);
}

.schedule .schedCal .react-calendar__tile--active:hover {
  opacity: 0.7;
  background-color: rgba(136, 136, 136, 0.5);
}

.schedule .schedCal .react-calendar__month-view__weekdays {
  margin-top: 20px;
}

.schedule .schedCal .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday {
  text-align: center;
}

.schedule .schedCal .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.schedule .tileCal {
  font-size: 11pt;
  font-family: "Raleway";
  padding: 9px;
  border: 1px solid rgba(50, 50, 50, 0.55);
  background-color: rgba(247, 247, 247, 0.8);
  border-radius: 2px;
  transition: 0.2s ease all;
}

.schedule .tileCal:hover {
  cursor: pointer;
  background-color: rgba(247, 245, 179, 0.9);
}

.memberContainer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50% 50%;
      grid-template-columns: 50% 50%;
  margin-top: 80px;
  padding-left: 15px;
}

.memberContainer .grid-item {
  margin: 5px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 48% 48%;
      grid-template-columns: 48% 48%;
  align-content: center;
}

.memberContainer .grid-item .memberBG {
  background: linear-gradient(0deg, #e4e0be 80%, #b86b61 80%) !important;
}

.memberContainer .grid-item .memberBG .header {
  width: 100%;
}

.memberContainer .grid-item .memberBG .header img {
  border: none;
  width: 45px;
  height: auto;
  margin-top: -50px;
}

.memberContainer .grid-item .memberBG .bio {
  padding: 20px;
  padding-top: 5px;
}

.memberContainer .grid-item .memberBG .bio p {
  font-size: 10pt;
  font-family: "Raleway";
}

.memberContainer .grid-item .submember {
  background: linear-gradient(0deg, #b35245 40%, #e7df94 40%);
  margin: 10px 4px;
  height: 270px;
  position: relative;
  box-shadow: 2px 2px 2px rgba(50, 50, 50, 0.2);
}

.memberContainer .grid-item .submember .memberSpot {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  background-color: #9baeff;
  z-index: 11;
  position: absolute;
  bottom: 15px;
  right: 5px;
  border: solid 3px #282828;
}

.memberContainer .grid-item .submember .memberSpot h3 {
  color: yellow;
  font-size: 14pt;
  transform: rotate(-10deg);
  margin-top: 19px;
  margin-left: 0px;
  text-align: center;
}

.memberContainer .grid-item .submember h2 {
  font-family: "Roboto";
  font-size: 8pt;
  text-align: right;
  margin-right: 10px;
  color: #303030;
}

.memberContainer .grid-item .submember h2 span {
  font-size: 14pt;
}

.memberContainer .grid-item .submember h3 {
  margin-top: 0px;
  margin-left: 17px;
  color: #6161ff;
  font-family: "Staatliches";
  font-size: 25pt;
  text-shadow: 2px 2px 0px #282828;
  z-index: 10;
  position: relative;
}

.memberContainer .grid-item .submember .mainPic {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 50%;
  height: 190px;
  width: 170px;
  border-radius: 15px;
  border: 5px solid white;
  margin-left: -88px;
  margin-top: -95px;
  -webkit-filter: contrast(100%);
          filter: contrast(100%);
}

.memberContainer .grid-item .submember .mainPic:before {
  border: 5px solid grey;
}

.memberContainer .grid-item .submember .facePic {
  position: absolute;
  top: 55px;
  left: 25px;
  margin-top: 0;
  height: 45px !important;
  width: 35px !important;
}

.memberContainer .grid-item .submember figcaption {
  position: absolute;
  bottom: 6px;
  left: 20px;
  font-size: 11pt;
  font-family: "Roboto";
  color: #2b2b2b;
  z-index: 5;
}

.merchContainer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50% 50%;
      grid-template-columns: 50% 50%;
}

.merchContainer .coaster {
  width: 250px;
  height: auto;
  margin-top: 150px;
  -webkit-filter: drop-shadow(2px 2px 2px rgba(60, 60, 60, 0.5));
  filter: drop-shadow(2px 2px 2px rgba(60, 60, 60, 0.5));
}

.merchContainer figcaption {
  font-family: "Staatliches";
  font-size: 20pt;
  color: #282828;
  margin-top: 20px;
}

.merchContainer figcaption span {
  text-decoration: line-through;
}

.contact-wrapper {
  width: 100%;
  height: 400px;
  position: absolute;
  top: 45%;
  left: 50%;
  margin-left: -50%;
  margin-top: -200px;
  padding-top: 50px;
}

.contact-wrapper p {
  font-family: "Raleway";
  font-size: 18pt;
  line-height: 50px;
}

.contact-wrapper p .emailContact {
  font-size: 33pt;
}

.contact-wrapper p .messageContact {
  font-size: 22pt;
}

.contact-wrapper a {
  color: #323232;
  transition: 0.2s ease all;
  margin: 0 15px;
}

.contact-wrapper a:hover {
  opacity: 0.7;
}

.contact-wrapper .ytIcon {
  color: #ff0000;
}

.contact-wrapper .fbIcon {
  color: #3b5998;
}

.contact-wrapper label {
  font-family: "Raleway";
  font-size: 12pt;
  color: #5c5c5c;
}

.contact-wrapper input {
  width: 100%;
  margin-bottom: 15px;
  margin-top: 8px;
  height: 30px;
  font-family: "Raleway";
  font-size: 12pt;
}

.contact-wrapper textarea {
  width: 101%;
  border: 1px solid #d8d8d8;
  margin-bottom: 15px;
  margin-top: 8px;
  font-family: "Raleway";
  font-size: 12pt;
}

.contact-wrapper button {
  position: absolute;
  bottom: 0;
  right: -8px;
  padding: 8px 30px;
  border: 1px solid rgba(54, 54, 54, 0);
  background-color: #5672f1;
  border-radius: 2px;
  font-family: "Raleway";
  font-size: 13pt;
  color: white;
  margin-top: 50px;
  transition: 0.2s ease all;
}

.contact-wrapper button:hover {
  cursor: pointer;
  opacity: 0.8;
  bottom: 3px;
}

.activeTab {
  right: 50%;
}

.activeAbout {
  right: 50%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  .tab {
    right: -100% !important;
    height: 70vh;
    width: 350px !important;
    margin-top: -85%;
    transition: 0.8s ease all;
    z-index: 11;
    box-shadow: 0px 0px 0px 150px rgba(50, 50, 50, 0);
  }
  .activeTab {
    right: 50% !important;
    margin-right: -175px;
    transition: 0.8s ease all;
    box-shadow: 0px 0px 0px 150px rgba(50, 50, 50, 0.8);
  }
  .activeAbout {
    right: 50% !important;
    margin-right: -193px;
    transition: 0.8s ease all;
    box-shadow: 0px 0px 0px 150px rgba(50, 50, 50, 0.8);
  }
  .tabExit {
    top: 5px !important;
    right: 0px !important;
  }
  .about {
    height: 665px !important;
    margin-top: -95% !important;
    width: 385px !important;
  }
  .about h1 {
    margin-top: 15px;
  }
  .about .memberContainer {
    margin-top: 60px;
  }
  .mainOpenerDiv .middleSectionContainer {
    width: 140px;
    height: 380px;
    margin-top: -46%;
    margin-left: -70px;
  }
  .mainOpenerDiv .middleSectionContainer .floatingHand {
    width: 100%;
    -webkit-animation-duration: 0.7s;
            animation-duration: 0.7s;
    opacity: 0.85;
  }
  .mainOpenerDiv .middleSectionContainer .shadow {
    width: 100%;
    margin-left: 10px;
    -webkit-animation-duration: 0.8s;
            animation-duration: 0.8s;
  }
  .memberContainer {
    padding: 10px 0;
  }
  .memberContainer .grid-item {
    margin: 0 0 0 5px;
  }
  .memberContainer .grid-item .submember {
    width: 42vw !important;
    height: 31vh;
  }
  .memberContainer .grid-item .submember h3 {
    font-size: 22pt;
    margin-left: 7px;
  }
  .memberContainer .grid-item .submember img {
    height: 67% !important;
    width: 35.5vw !important;
    margin-left: -19vw !important;
    margin-top: -11vh !important;
  }
  .memberContainer .grid-item .submember .memberSpot {
    height: 60px;
    width: 60px;
    bottom: 35px;
    right: 5px;
  }
  .memberContainer .grid-item .submember .memberSpot h3 {
    font-size: 14pt;
    margin-top: 19px;
    margin-left: 0px;
  }
  .memberContainer .grid-item .submember figcaption {
    left: 10px;
  }
  .memberContainer .grid-item .memberBG {
    visibility: collapse !important;
  }
  .media {
    box-shadow: 0px 0px 0px 150px rgba(50, 50, 50, 0.8);
    height: 430px;
    top: 60vh;
  }
  .media .mediaContainer {
    width: 95%;
    margin-left: -47.5%;
    margin-top: -27%;
  }
  .media .mediaContainer .backBtn {
    top: 0;
    padding: 0;
    width: 30px;
    height: 222px !important;
    opacity: 1;
  }
  .media .mediaContainer .backBtn:hover {
    opacity: 1;
    left: -5px;
  }
  .media .mediaContainer .nextBtn {
    top: 0;
    padding: 0;
    width: 30px;
    height: 222px !important;
    opacity: 1;
  }
  .media .mediaContainer .nextBtn:hover {
    opacity: 1;
    right: -5px;
  }
  .media .mediaContainer li {
    height: 100%;
  }
  .media .mediaContainer .carousel__slider {
    height: 222px;
    overflow: hidden;
  }
  .media .mediaContainer .dotgroup {
    position: absolute;
    bottom: 5vh;
    z-index: 10;
    text-align: center;
  }
  .media .mediaContainer .dotgroup .dot___3c3SI,
  .media .mediaContainer .dotgroup .carousel__dot {
    height: 10px !important;
    width: 10px !important;
    overflow: hidden;
    border: none;
    background-color: #c2c2c2;
    margin: 0 5px;
  }
  .media .mediaContainer .dotgroup .carousel__dot--selected {
    background-color: rgba(253, 241, 68, 0.95);
    border: 1px solid rgba(50, 50, 50, 0.3);
  }
  .merchContainer {
    -ms-grid-columns: 100%;
        grid-template-columns: 100%;
    height: 100%;
  }
  .merchContainer .merch1 {
    margin-top: 100px;
    height: 220px;
  }
  .merchContainer .merch2 {
    height: 220px;
  }
  .merchContainer .coaster {
    width: 130px;
    margin-top: 0px;
  }
  .merchContainer figcaption {
    font-size: 13pt;
    margin-top: 10px;
  }
  .contact {
    height: 505px;
    top: 56vh;
  }
  .contact-wrapper {
    padding-top: 100px;
  }
  .contact-wrapper p {
    font-size: 12pt;
    line-height: 35px;
  }
  .contact-wrapper p .emailContact {
    font-size: 18pt;
  }
  .contact-wrapper p .messageContact {
    font-size: 16pt;
  }
  .schedule h4 {
    position: absolute;
    top: 120px;
    left: 35px;
    height: 30px;
    font-size: 16pt;
  }
  .schedule .showsList {
    position: absolute;
    top: 200px;
    left: 50%;
    width: 90%;
    margin-left: -45%;
    display: -ms-grid;
    display: grid;
    grid-row-gap: 15px;
    padding: 0;
    justify-content: center;
    grid-row-gap: 10px;
  }
  .schedule .showsList li {
    list-style: none;
    font-family: "Raleway";
    font-size: 13pt;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 100%;
        grid-template-columns: 100%;
    max-height: 110px !important;
  }
  .schedule .showsList li a {
    color: blue;
  }
  .schedCal {
    visibility: collapse;
  }
  .topLeftText {
    top: 8px;
    left: 30px;
    font-size: 22pt;
  }
  .menuContainer {
    right: -15px !important;
    top: -5px;
    transform: scale(0.8) !important;
  }
  .menuContainer:hover {
    cursor: pointer;
    top: -5px;
    opacity: 0.7;
  }
  .navbar {
    top: 60px;
    z-index: 10;
  }
  .activeNav {
    right: 17px;
    top: 60px;
  }
  .navItem {
    font-size: 15pt;
    background-color: #fcfcfc;
    padding: 10px;
    opacity: 0.9 !important;
  }
  .navItem:hover {
    padding-right: 10px !important;
    opacity: 0.9;
  }
  .socials {
    bottom: 17vh;
    width: 200px;
    margin-left: -100px;
  }
  .socials .fbIcon:hover {
    color: rgba(50, 50, 50, 0.8);
    margin-bottom: 0px;
  }
  .socials .instaIcon:hover {
    color: rgba(50, 50, 50, 0.8);
    margin-bottom: 0px;
  }
  .socials .ytIcon:hover {
    color: rgba(50, 50, 50, 0.8);
    margin-bottom: 0px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=App.css.map */
